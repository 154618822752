export const _bytesToSize = (bytes: number) => {
  const MARKER = 1000;
  const FLOAT = 1;
  const KILLOBYTES = MARKER;
  const MEGABYTES = MARKER ** 2;
  const GIGABYTES = MARKER ** 3;
  const TERABYTES = MARKER ** 4;
  let result;

  if (bytes < KILLOBYTES) result = `${bytes} Bytes`;
  else if (bytes < MEGABYTES)
    result = `${(bytes / KILLOBYTES).toFixed(FLOAT)} KB`;
  else if (bytes < GIGABYTES)
    result = `${(bytes / MEGABYTES).toFixed(FLOAT)} MB`;
  else if (bytes < TERABYTES)
    result = `${(bytes / GIGABYTES).toFixed(FLOAT)} GB`;
  else result = `${(bytes / TERABYTES).toFixed(FLOAT)} TB`;

  return result;
};

export const _getValueAndUnitOfBytes = (
  bytes: number
): { unit: 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB'; value: number } => {
  const MARKER = 1024;
  const KILLOBYTES = MARKER;
  const MEGABYTES = MARKER ** 2;
  const GIGABYTES = MARKER ** 3;
  const TERABYTES = MARKER ** 4;
  let unit: 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB';
  let value: number;

  if (bytes < KILLOBYTES) {
    unit = 'Bytes';
    value = bytes;
  } else if (bytes < MEGABYTES) {
    unit = 'KB';
    value = bytes / KILLOBYTES;
  } else if (bytes < GIGABYTES) {
    unit = 'MB';
    value = bytes / MEGABYTES;
  } else if (bytes < TERABYTES) {
    unit = 'GB';
    value = bytes / GIGABYTES;
  } else {
    unit = 'TB';
    value = bytes / TERABYTES;
  }

  return { unit, value: +value };
};

export const safeJSONParse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    console.log('Error parsing JSON:', error);
    return null;
  }
};

/**
 * @description data를 받아서 안전하게 JSON.stringify를 수행하는 메서드. 에러 발생시 null을 반환한다.
 */
export const safeJSONStringify = (data: any): string | null => {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const setItemWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();

  const item = {
    value: value,
    exp: now.getTime() + ttl,
  };
  const serializedItem = safeJSONStringify(item);

  if (serializedItem !== null) {
    localStorage.setItem(key, serializedItem);
  }
};

export const getItemWithExpiry = (key: string) => {
  if (typeof window === 'undefined') return null;

  const itemStr = localStorage.getItem(key);

  if (!itemStr) return null;

  try {
    const item = safeJSONParse(itemStr);
    const now = new Date();

    if (now.getTime() > item.exp) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (e) {
    console.log(e);
    return null;
  }
};
