import { Quality } from '../components/Carousel/images';
import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from 'react';

export type ConvertedImg = {
  src: string;
  size: number;
};

export type OriginalImg = ConvertedImg & {
  container: string;
};

export type SizeObj = {
  size: number;
  savedPercentage: number;
};

export type ConvertedImgFormat = Record<Quality, ConvertedImg>;
export type SizeObjFormat = Record<Quality, SizeObj>;
export type ImgContextState = {
  isUploading: boolean;
  isUserImage: boolean;
  convertedImgs: ConvertedImgFormat | null;
  uploadedImg: OriginalImg | null;
  isOpenModal: boolean;
  errorMsg: string;
  sizeObj: SizeObjFormat | null;
};

type Action =
  | { type: 'change'; newState: Partial<ImgContextState> }
  // | { type: 'addSizes'; sizeObj: ImgContextState['sizeObj'] }
  | { type: 'resetSizes' }
  | { type: 'closeModal' };

type ImgLoadContextType = {
  state: ImgContextState;
  dispatch: Dispatch<Action>;
};

type ImgReducer = Reducer<ImgContextState, Action>;

const ImgContext = createContext<ImgLoadContextType>({
  state: {
    isUploading: false,
    convertedImgs: null,
    uploadedImg: null,
    isUserImage: false,
    isOpenModal: false,
    errorMsg: '',
    sizeObj: null,
  },
  dispatch: () => {},
});

const ImgContextReducer: ImgReducer = (state, action: Action) => {
  switch (action.type) {
    case 'change': {
      return { ...state, ...action.newState };
    }
    case 'closeModal': {
      return { ...state, isOpenModal: false };
    }
    case 'resetSizes': {
      return { ...state, sizeObj: null };
    }

    default: {
      //@ts-ignore
      throw new Error(`unhandled action type: ${action?.type || ''}`);
    }
  }
};

export const ImgContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<ImgReducer>(ImgContextReducer, {
    isUploading: false,
    isUserImage: false,
    convertedImgs: null,
    uploadedImg: null,
    isOpenModal: false,
    errorMsg: '',
    sizeObj: null,
  });

  const value: ImgLoadContextType = { state, dispatch };

  return <ImgContext.Provider value={value}>{children}</ImgContext.Provider>;
};

export const useImgContext = () => {
  const context = useContext(ImgContext);

  if (context === undefined)
    throw new Error('use context must be used within a AppContext Provider.');

  return context;
};
